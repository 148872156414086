import {FormObject} from '@components/forms/form.t'
import {BasicDomain, PriceBundle, SaleArticle, SupplierArticle} from '@shared/interfaces'

interface ProductDomain extends BasicDomain, FormObject {
  _id: string
  name: string
  description: string
  isCatalog: boolean
}

export enum CartItemStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  PENDING_SALE = 'pendingSaleArticle',
  PENDING_SUPPLIER = 'pendingSupplierArticle',
  MISSING = 'missing'
}

export interface CartItemSearchContext extends FormObject {
  product: ProductDomain | string
  service: string
}

export interface CartItemDomain extends BasicDomain {
  name: string
  description: string
  requiredPriceInformation: boolean
  regulatoryDescription: string
  priceInformation: string
  internalDescription: string
  price?: PriceBundle
  buyingPrice?: PriceBundle
  supplierRef: string
  saleArticle: SaleArticle
  supplierArticle: SupplierArticle
  status: CartItemStatus
  product: ProductDomain
  searchContext?: CartItemSearchContext
}

export enum CartStatus {
  ESTIMATE = 'estimate',
  ORDER = 'order',
  INVOICE = 'invoice'
}

export interface CartSubCategory extends BasicDomain {
  name: string
  articles: CartItemDomain[]
}

export interface CartCategory extends BasicDomain {
  name: string
  color: string
  fontColor: string
  subCategories: CartSubCategory[]
}

export interface CartCategories {
  internal: CartCategory[]
  regulatory: CartCategory[]
  hasMarbleWork: boolean
}

interface StringPriceBundle {
  ht: string
  tva: string
  ttc: string
}

export interface CartPricesTVAs {
  summary: StringPriceBundle
}

export interface CartPrices {
  tvas: CartPricesTVAs
}

export interface CartDomain extends BasicDomain {
  status: CartStatus
  cartItems: CartItemDomain[]
  categories: CartCategories
  prices: CartPrices
  locked: boolean
  isAbandoned: boolean
}
