import {CloseOutlined} from '@ant-design/icons'
import CustomTable from '@components/custom-table'
import LookupTableCell from '@components/lookup-component/lookup-table-cell'
import {PanelsKeys} from '@libs/panels/panels.t'
import {LookupItem} from '@shared/erp-api'
import {AppContext, withApp} from '@store/app'
import {
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Space,
  TableColumnsType,
  TablePaginationConfig,
  Typography
} from 'antd'
import {GetRowKey} from 'antd/es/table/interface'
import React, {useMemo, useState} from 'react'

export interface ResultViewProps extends AppContext {
  headerTitle: string
  result: LookupItem[]
  onItemClicked?: (record: LookupItem | undefined) => void
  rowKey?: string | GetRowKey<LookupItem>
  onClose: (key: PanelsKeys) => void
}

export const PanelHeader: React.FC<
  React.PropsWithChildren<{
    title: string | null
    panelKey: PanelsKeys
    onClose: (key: PanelsKeys) => void
  }>
> = ({title, panelKey, children, onClose}) => (
  <div>
    <Row justify='space-between'>
      <Col>
        <Typography.Title level={4}>{title}</Typography.Title>
      </Col>
      <Col>
        <Row>
          <Space>
            {children && children}
            <Button icon={<CloseOutlined />} onClick={() => onClose(panelKey)} />
          </Space>
        </Row>
      </Col>
    </Row>
    <Divider style={{marginTop: 5, marginBottom: 5}} />
  </div>
)

const ResultView: React.FC<ResultViewProps> = ({
  t,
  result,
  onItemClicked,
  headerTitle,
  onClose,
  rowKey = '_id'
}: ResultViewProps) => {
  const [selectedLookupItem, setSelectedLookupItem] = useState<LookupItem>()

  const columns: TableColumnsType<LookupItem> = useMemo(
    () => [
      {
        dataIndex: 'displayValues',
        render: (_: string[], record: LookupItem) => {
          return (
            <LookupTableCell lookupItem={record} compact={false} key={record._id} id={undefined} />
          )
        }
      }
    ],
    []
  )

  return (
    <div style={{height: '100%'}}>
      <PanelHeader title={t(headerTitle)} panelKey={PanelsKeys.RESULT_VIEW} onClose={onClose} />
      {!result.length && (
        <Empty style={{padding: '40px'}} description={t('panel.productFinder.empty')}>
          <Button
            type='primary'
            target='_blank'
            href='https://www.notion.so/b16f5bdde280422fb89f1dec0a1598f0?v=6fc54eb3e3e246f6aae661a39f8e596d&pvs=25'>
            {t('panel.productFinder.report')}
          </Button>
        </Empty>
      )}
      {!!result.length && (
        <CustomTable
          rowKey={rowKey}
          compact={false}
          data={result}
          columns={columns}
          pageSize={result.length}
          total={result.length}
          isLoading={false}
          page={1}
          handleTableChange={(pagination: TablePaginationConfig) => {
            console.log(pagination)
          }}
          id={undefined}
          onItemClick={onItemClicked}
          selectedLookupItem={selectedLookupItem}
          setSelectedLookupItem={setSelectedLookupItem}
          showHeader={false}
        />
      )}
    </div>
  )
}

export default withApp(ResultView)
