import {FormObject, FormObjectValue} from '@components/forms/form.t'
import {ErpDomain} from '@shared/interfaces'
import OID from 'bson-objectid'
import * as jsonpatch from 'fast-json-patch'
import _ from 'lodash'
import qs from 'querystring'
import ObjectPath from 'object-path'
import object from './object'
import * as string from './string'
import {ParsedUrlQueryInput} from 'querystring'

export const isOID = (id: string) => OID.isValid(id) && id.length == 24

export const getId = (value: FormObject | string) =>
  typeof value === 'string' ? isOID(value) && value : value._id

export enum ClipboardMethod {
  CLIPBOARD = 'clipboard',
  ELEMENT = 'element'
}

/**
 *
 * This method allows you to copy a string to the clipboard via two methods:
 * 1. Navigator Clipboard API
 * 2. DOM Element and emulating a copy event
 * @param {string} textstring
 * @param {ClipboardMethod} method:ClipboardMethod.CLIPBOARD
 * @returns {void}
 */
export const toClipboard = (text: string, method = ClipboardMethod.CLIPBOARD) => {
  if (method == 'clipboard') return navigator.clipboard.writeText(text)

  // Create new element
  const el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = text
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  // el.style = {position: 'absolute', left: '-9999px'}
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

export const atPath = ObjectPath.get
export const setPath = ObjectPath.set

export const asArray = (
  input: string | string[],
  {delim = ',', trim = true, uniq = true, compact = true} = {}
) => {
  let output = typeof input == 'string' ? input.split(delim) : input || []

  if (trim) output = _.map(output, (v) => (typeof v == 'string' ? v.trim() : v))
  if (compact) output = _.compact(output)
  if (uniq) output = _.uniq(output)

  return output
}

export const getHash = (input: string) => {
  if (!input) return
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i)
    hash |= 0 // to 32bit integer
  }
  return hash
}

export const selectColor = (number: number) => {
  if (!number) return
  const hue = number * 137.508 // use golden angle approximation
  return `hsl(${hue},100%,70%)`
}

export const stringToColor = (str: string) => {
  if (!str) return
  return str.startsWith('#') ? str : selectColor(getHash(str) || 0)
}

export const relativePath = (path: string) => {
  const split = path.split('.')
  if (split.length < 2) return false

  split.pop()
  return split.join('.')
}

export const cleanObjectFromSchema = (domain: ErpDomain, item: FormObject) => {
  return _.pick(
    item,
    _.intersection(
      [
        ...Object.keys(domain.fields),
        ...(domain.mVirtuals
          .map(({key}) => {
            if (domain.relations.some((r) => r.keyPlural == key && r.isChild)) return false
            return key
          })
          .filter(Boolean) as string[])
      ],
      Object.keys(item)
    )
  )
}

const cleanNested =
  (cleanObjects: (item: FormObject) => FormObject) =>
  (item: FormObject | FormObjectValue): FormObjectValue | FormObject => {
    if (item && typeof item === 'object' && !(item instanceof Date) && !Array.isArray(item)) {
      return item?._id && item.__type ? item._id : cleanObjects(item)
    }

    return item
  }

const cleanObjects = (item: FormObject) => {
  const cleanNestedObjects = cleanNested(cleanObjects)

  return Object.entries(_.cloneDeep(item)).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = value.map(cleanNestedObjects) as FormObject[] | FormObjectValue[]
    } else {
      acc[key] = cleanNestedObjects(value as FormObject | FormObjectValue)
    }

    return acc
  }, {} as FormObject)
}

export const saveDiff = (domain: ErpDomain, initialItem: FormObject, item: FormObject) => {
  const [cleanInitialItem, cleanItem] = [initialItem, item].map((v) =>
    cleanObjects(cleanObjectFromSchema(domain, v))
  )

  const diff = jsonpatch
    .compare(cleanInitialItem, cleanItem)
    .filter((item) =>
      item.op === 'add'
        ? item.value !== '' && item.value !== null && item.value !== undefined
        : true
    )

  return diff
}

type IteratorFn<T, R> = (item: T, index: number) => Promise<R>

export const serie = async <T, R>(items: T[] = [], iterator: IteratorFn<T, R>): Promise<R[]> =>
  items
    .map((item, i) => () => iterator(item, i))
    .reduce(async (acc, fn) => (await acc).concat(await fn()), Promise.resolve([] as R[]))

export const parallel = async <T, R>(items: T[] = [], iterator: IteratorFn<T, R>): Promise<R[]> =>
  Promise.all(items.map(iterator))

export const allSettled = async <T, R>(
  items: T[] = [],
  iterator: IteratorFn<T, R>
): Promise<PromiseSettledResult<R>[]> => Promise.allSettled(items.map(iterator))

export const buildUrl = (url: string, params: ParsedUrlQueryInput) =>
  url + (url.includes('?') ? '&' : '?') + qs.stringify(params)

const generateFile = (type: string) => (data: string) => {
  const blob = new Blob([data], {type})

  return URL.createObjectURL(blob)
}

export const generateCSV = generateFile('text/csv')

export const downloadCSV = (name: string, data: string) => {
  const date = new Date().toISOString().split('T')[0]
  const a = document.createElement('a')

  a.href = generateCSV(data)
  a.download = `${name}_${date}.csv`
  a.click()
}

export default {string, object}
