import {TFunction} from 'i18next'
import React from 'react'
import {FormikProps} from 'formik'
import {FormObject} from '@components/forms/form.t'

export enum PanelsKeys {
  LOOKUP = 'lookup',
  ENTITY_FINDER = 'entityFinder',
  FORM = 'form',
  FORM_ADD = 'formAdd',
  FORM_EDIT = 'formEdit',
  PRODUCT_FINDER = 'productFinder',
  CATALOG = 'catalog',
  RESULT_VIEW = 'resultView'
}

export interface Panels {
  panels: PanelState[]
  isOpen(key: PanelsKeys): boolean
  show(key: PanelsKeys, props: object, style?: PanelStyle, panelProps?: object): void
  toggle(key: PanelsKeys, props: object, style?: PanelStyle, panelProps?: object): void
  close(key: PanelsKeys): void
}

export enum PanelStyle {
  MODAL = 'modal',
  DRAWER = 'drawer'
}

export interface PanelState {
  key: PanelsKeys
  props: object
  panelProps: object
  style: PanelStyle
  render?: React.ReactElement
  title?: string
  closable?: boolean
  formRef?: React.MutableRefObject<FormikProps<FormObject> | undefined>
}

export interface PanelsWarpperProps {
  control: Panels
  t: TFunction
}
