import {LookupItem} from '@shared/erp-api'
import {Table, TableColumnsType, TablePaginationConfig} from 'antd'
import {Dispatch} from 'react'
import {generateCellId} from './utils'
import {GetRowKey} from 'antd/es/table/interface'

interface CustomTableProps {
  compact: boolean
  data: LookupItem[] | undefined
  columns: TableColumnsType<LookupItem>
  pageSize: number
  total: number
  page: number
  isLoading: boolean
  handleTableChange: (pagination: TablePaginationConfig) => void
  id: string | undefined
  onItemClick: ((record?: LookupItem) => void) | undefined
  setSelectedLookupItem: Dispatch<LookupItem | undefined> | undefined
  selectedLookupItem: LookupItem | undefined
  showHeader?: boolean
  rowKey?: string | GetRowKey<LookupItem>
}

const CustomTable = ({showHeader = true, rowKey = '_id', ...props}: CustomTableProps) => {
  return (
    <Table
      scroll={{y: props.compact ? 'calc(100vh - 265px)' : 'calc(100vh - 165px)'}}
      rowKey={rowKey}
      dataSource={props.data || []}
      columns={props.columns}
      showHeader={showHeader}
      pagination={{
        pageSize: props.pageSize,
        total: props.total,
        current: props.page,
        position: ['topCenter'],
        showSizeChanger: false,
        size: 'small',
        hideOnSinglePage: true,
        ...(props.compact && {className: 'my-1'})
      }}
      loading={props.isLoading}
      onChange={props.handleTableChange}
      onRow={(record) => {
        return {
          onClick: () => {
            const select = document.getElementById(generateCellId(record, props.id))
            select?.focus()
            select?.click()
            props.onItemClick?.(record)
          },
          onChange: () => {
            if (props.setSelectedLookupItem) props.setSelectedLookupItem(record)
          }
        }
      }}
      rowSelection={{
        selectedRowKeys: props.selectedLookupItem ? [props.selectedLookupItem._id] : undefined,
        type: 'radio',
        columnWidth: 0,
        getCheckboxProps: (record) => ({
          id: generateCellId(record, props.id),
          // This is a hack to be able to use radio buttons native functionality (keyboard navigation, etc.)
          // display: 'none' doesn't work
          style: {
            width: 0,
            height: 0,
            position: 'fixed',
            left: '-4000px'
          }
        })
      }}
      {...(props.compact && {size: 'small'})}
    />
  )
}

export default CustomTable
