import React from 'react'
import {PanelsKeys} from '@libs/panels/panels.t'
import FormPanel, {FormPanelProps} from './form'
import FormAddPanel, {FormAddPanelProps} from './formAdd'
import EntityFinder, {EntityFinderPanelProps} from './entityFinder'
import FormEditPanel, {FormEditPanelProps} from './formEdit'
import CatalogPanel, {CatalogPanelProps} from './catalog'
import ProductFinder, {ProductFinderPanelProps} from './productFinder'
import ResultView, {ResultViewProps} from './resultView'

const dummyPanel: React.FC = () => {
  return <div>Dummy Panel</div>
}

export type PanelTypes =
  | React.FC
  | React.FC<FormPanelProps>
  | React.FC<FormAddPanelProps>
  | React.FC<EntityFinderPanelProps>
  | React.FC<FormEditPanelProps>
  | React.FC<CatalogPanelProps>
  | React.FC<ProductFinderPanelProps>
  | React.FC<ResultViewProps>

const Panels: Record<PanelsKeys, PanelTypes> = {
  [PanelsKeys.LOOKUP]: dummyPanel,
  [PanelsKeys.ENTITY_FINDER]: EntityFinder,
  [PanelsKeys.FORM]: FormPanel,
  [PanelsKeys.FORM_ADD]: FormAddPanel,
  [PanelsKeys.FORM_EDIT]: FormEditPanel,
  [PanelsKeys.PRODUCT_FINDER]: ProductFinder,
  [PanelsKeys.CATALOG]: CatalogPanel,
  [PanelsKeys.RESULT_VIEW]: ResultView
}

export default Panels
